import React from 'react';
import ProductDetailsLayout from '../components/ProductDetails/index';
import { graphql } from 'gatsby';
import { modifyContent, featuredOrLatestPosts } from '../utils/helpers';

// If meta will be different for all the product pages then need to create different query for all else keep it same

export const query = graphql`
        query{
            meta: allGhostPage (
                filter: {
                    tags : { elemMatch : { name: {
                        in:[ "#loan" ]
                        eq: "#meta"
                    }}}
                }
            ){
                nodes {
                    plaintext
                    meta_title
                    meta_description
                }
            }
            banner: allGhostPage(
                filter: {
                    tags: { elemMatch: { name: {
                        in:[ "#loan" ]
                        eq: "#banner"
                    }}},
                }
            ) {
                nodes {
                    plaintext
                }
            }
            productCard: allGhostPage(
                filter: {
                    tags: { elemMatch: { name: {
                        in:[ "#loan" ]
                        eq: "#product-cards"
                    }}},
                }
            ) {
                nodes {
                    plaintext
                }
            }
            productFeedback: allGhostPage(
                filter: {
                    tags: { elemMatch: { name: {
                        in:[ "#loan" ]
                        eq: "#product-feedback"
                    }}},
                }
            ) {
                nodes {
                    plaintext
                }
            }
            productList: allGhostPage(
                filter: {
                    tags: { elemMatch: { name: {
                        eq: "#product-list",
                        ne:"#homepage"
                    }}},
                }
            ) {
                nodes {
                    plaintext
                }
            }
            stepsCarousel: allGhostPage(
                filter: {
                    tags: { elemMatch: { name: {
                        in:[ "#loan" ]
                        eq: "#steps-carousel"
                    }}},
                }
            ) {
                nodes {
                    plaintext
                }
            }

            curve: allGhostPage(
                filter: {
                    tags: { elemMatch: { name: { eq: "#curve-design"}}}
                }
            ) {
                nodes {
                    plaintext
                }
            }

            featuredBlog: allGhostPost(
                limit: 4,
                filter:{
                    featured:{eq: true}
                    tags:{elemMatch:{name:{in:[ "Business Loan" ]}}}
                }
  	            sort: { order: DESC, fields: [published_at] }
            ){
                edges{
                    node{
                    ...GhostPostFields
                }
            }
        }

            allLatestPost: allGhostPost(
                limit: 4,
                filter:{
                    featured:{eq: false}
                    tags:{elemMatch:{name:{in:[ "Business Loan" ]}}}
                }
  	            sort: { order: DESC, fields: [published_at] }
                ){
                    edges{
                node{
                    ...GhostPostFields
                    }
            }
        }

        blogSectionMeta: allGhostPage (
                filter: {
                    tags : { elemMatch : { name: { eq: "#blog" }}}
                }
            ){
                nodes {
                    plaintext
            }
        }
    }
`;

const LoansPage = props => {
    const data = modifyContent(props.data);
    let featuredBlogArticles = props.data.featuredBlog.edges;
    const latestPost = props.data.allLatestPost.edges;

    let featuredPosts = featuredOrLatestPosts(featuredBlogArticles, latestPost, 4)

    return (
        <ProductDetailsLayout
            data={data}
            title={props.data.meta.nodes[0].meta_title}
            description={props.data.meta.nodes[0].meta_description}
            location={props.location}
            featuredBlogArticles={featuredPosts}
        />
    );
}


export default LoansPage;
